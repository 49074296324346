import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface SubMenuOption {
    id: string;
    title: string;
}

interface Props {
    currentMenuId: SubMenuOption['id'];
    options: SubMenuOption[];
    selectMenu: (id: any) => void;
}

export default function SubMenuToggle (props: Props) {
    const { options, currentMenuId, selectMenu } = props;

    return (
        <Stack spacing={2}>
            {options.map(option => (
                <Box
                    sx={{
                        padding: '10px',
                        borderLeft: '3px solid',
                        width: '150px',
                        backgroundColor: option.id === currentMenuId ? 'primary.faded' : 'primary.main',
                        borderColor: option.id === currentMenuId ? 'highlight.main' : 'transparent',
                        '&:hover': {
                            color: 'primary.faded',
                            cursor: 'pointer'
                        }
                    }}
                    onClick={() => selectMenu(option.id)}>
                    <Typography variant='h4' color='secondary.main' fontWeight='bold'>{option.title}</Typography>
                </Box>
            ))}
        </Stack>
    )
}