import { Category } from './categories';
import { WidgetType, WidgetData } from '../../components/widgets/Widget';

export type Offering = {
    title: string
    description: string
    price: string
    categories: Category['identifier'][]
    widget: WidgetType
    widgetData: WidgetData
}

const adultMemberships: Offering[] = [
    {
        title: 'Gym Only',
        description: 'Unlimited access to our state-of-the-art gym, including cardio, resistance, strength, and conditioning equipment. Access to the bouldering wall during staffed hours.',
        price: '$55/month',
        categories: ['adults'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '103'
        }
    },
    {
        title: 'Classes Only',
        description: 'Attend up to two classes per week, led by our incredible team of instructors. Includes various strength training classes, yoga, ballet barre, and more.',
        price: '$70/month',
        categories: ['adults'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '102'
        }
    },
    {
        title: 'Gym and Classes',
        description: 'Unlimited access to our state-of-the-art gym + up to two classes per week.',
        price: '$100/month',
        categories: ['adults'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '104'
        }
    },
    {
        title: 'Classes Unlimited',
        description: 'Unlimited access to our class line-up, led by our incredible team of instructors.',
        price: '$100/month',
        categories: ['adults'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '110'
        }
    },
    {
        title: 'Gym and Classes Unlimited',
        description: 'Unlimited access to our state-of-the-art gym + unlimited classes + special monthly programs.',
        price: '$140/month',
        categories: ['adults'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '109'
        }
    }
]

const teenMemberships: Offering[] = [
    {
        title: 'Teenage Gym Only',
        description: 'Unlimited access to our state-of-the-art gym, including cardio, resistance, strength, conditioning equipment, and bouldering wall during staffed hours.',
        price: '$30/month',
        categories: ['teens'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '108'
        }
    },
    {
        title: 'Teenage Classes Only',
        description: 'Attend up to two classes per week, led by our incredible team of instructors. Includes various strength training classes, yoga, ballet barre, and more.',
        price: '$50/month',
        categories: ['teens'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '106'
        }
    },
    {
        title: 'Teenage Gym and Classes',
        description: 'Unlimited access to our state-of-the-art gym during staffed hours + up to two classes per week.',
        price: '$70/month',
        categories: ['teens'],
        widget: 'mindbody-link-contract',
        widgetData: {
            dataServiceId: '107'        
        }
    }
]

const seniorMemberships: Offering[] = [
    {
        title: 'Silver Sneakers',
        description: `"SilverSneakers is a fitness and wellness program offered at no additional cost to seniors 65+ on eligible Medicare plans that helps you get active, get fit, and connect with others.” 
        
        Members of Silver Sneakers get free access to the gym, cardio equipment, and weight room. 
        
        Eligible members must email us at info@goathousegym.com to enroll. Please include your 16-digit ID, name, date of birth, and phone number. Check your eligibility and get more information at https://tools.silversneakers.com/.
        `,
        price: 'Free',
        categories: ['seniors'],
        widget: 'empty-widget',
        widgetData: {}
    }
]

const singleAccessDropIn: Offering[] = [
    {
        title: 'Drop In',
        description: 'Can be used for either gym access or class attendance. Expires 2 months after purchase. Must be 13 years or older to access weight room or classes.',
        price: '$20',
        categories: ['single-access-drop-in'],
        widget: 'mindbody-link-pricing-option',
        widgetData: {
            dataServiceId: '100018'
        }
    },
    {
        title: 'Kids 12 and Under Bouldering Drop-In',
        description: 'Can be used to access the bouldering wall during staffed hours. Children must be accompanied by supervising adult. Expires 2 months after purchase.',
        price: '$5',
        categories: ['single-access-drop-in'],
        widget: 'mindbody-link-pricing-option',
        widgetData: {
            dataServiceId: '100044'
        }
    }
]

const punchCards: Offering[] = [
    {
        title: '5 Pack',
        description: 'Come to the gym 5 times. Expires 2 months after purchase.',
        price: '$80',
        categories: ['punch-cards'],
        widget: 'mindbody-link-pricing-option',
        widgetData: {
            dataServiceId: '100043'
        }
    },
    {
        title: '10 Pack',
        description: 'Come to the gym 10 times, and use your visits for either gym access or class attendance. You have the flexibility to combine both options. Expires 3 months after purchase.',
        price: '$140',
        categories: ['punch-cards'],
        widget: 'mindbody-link-pricing-option',
        widgetData: {
            dataServiceId: '100019'
        }
    }
]

const kidsDance: Offering[] = [
    {
        title: '',
        description: '',
        price: '',
        categories: ['kids-dance'],
        widget: 'mindbody-enrollment',
        widgetData: {
            dataWidgetId: '5e957295e57'
        }
    }
]

const oneOnOnePersonalTraining: Offering[] = [
    {
        title: 'Single Session',
        description: 'Personal training is tailored to your unique goals, abilities, and schedule. Whether you want less pain, better mobility, weight loss, muscle gain, or general fitness, we’re here to empower you to help reach your goals. You’ll receive one-on-one guidance, goal setting, and accountability to keep you on track.',
        price: '$45',
        categories: ['1-on-1'],
        widget: 'mindbody-link-pricing-option',
        widgetData: {
            dataServiceId: '100118'
        }
    }
]
const smallGroupPersonalTraining: Offering[] = [
    {
        title: 'Single Session',
        description: 'Experience the benefits of personal training with the added motivation and energy of a small group! Our small group sessions are designed for up to 4 individuals, allowing for personalized attention while fostering a supportive and encouraging environment. You’ll still receive tailored workout plans, expert guidance on form, and goal-focused progress tracking, at a more affordable rate than one-on-one. Price is per person.',
        price: '$30',
        categories: ['small-group'],
        widget: 'mindbody-link-pricing-option',
        widgetData: {
            dataServiceId: '100173'
        }
    }
]

export default [ ...adultMemberships, ...teenMemberships, ...seniorMemberships, ...singleAccessDropIn, ...punchCards, ...kidsDance, ...oneOnOnePersonalTraining, ...smallGroupPersonalTraining ]
