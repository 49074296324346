
import { MindBodyLinkData } from "./mindbody/MindBodyLink";
import MindBodyPricingLink from "./mindbody/MindBodyPricingLink";
import MindBodyContractLink from './mindbody/MindBodyContractLink';
import MindBodyEnrollmentWidget, { MindBodyEnrollmentWidgetData } from './mindbody/MindbodyEnrollmentWidget'

interface InternalLinkData {
    linkTo: string
}

interface ExternalLinkData {
    dataUrl: string
}

interface EmptyWidget {}

export type WidgetType = 'mindbody-link-contract' | 'mindbody-link-pricing-option' | 'mindbody-enrollment' | 'external-link' | 'internal-link' | 'empty-widget'

// This typing could be better - ideally we would require one of the following
export type WidgetData =
    | InternalLinkData
    | MindBodyLinkData
    | MindBodyEnrollmentWidgetData
    | ExternalLinkData
    | EmptyWidget

type Props = {
    widgetType: WidgetType
    widgetData: WidgetData
}

export default function Widget (props: Props) {
    const { widgetType } = props;

    switch (widgetType) {
        case 'mindbody-link-contract':
            return (
                <MindBodyContractLink {...props.widgetData as MindBodyLinkData} />
            )

        case 'mindbody-link-pricing-option':
            return (
                <MindBodyPricingLink {...props.widgetData as MindBodyLinkData} />
            )

        case 'mindbody-enrollment':
            return (
                <MindBodyEnrollmentWidget {...props.widgetData as MindBodyEnrollmentWidgetData} />
            )

        case 'external-link':
        case 'internal-link':
        case 'empty-widget':
            return null

        default:
            return null
    }


}