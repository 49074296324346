import { useState } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import MenuToggle from './MenuToggle';
import MenuDropdown from './MenuDropdown';
import MenuItem from './MenuItem';

import Callout from '../cards/Callout';
import MindbodyEnrollmentsWidget from '../widgets/mindbody/MindbodyEnrollmentWidget'

import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';

import PricingService from '../../services/pricing/PricingService';
import { Group } from '../../services/pricing/groups'

interface Props {
    title: string;
    groups: Group[];
    menu: Group['identifier']
    calloutText?: string;

}

const menuCallouts: { [key: string]: string } = {
    //memberships: 'Buy a membership and receive 3 free personal training sessions!',
}

export default function Menus (props: Props, prevProps: Props) {
    const { 
        title,
        menu
    } = props;

    const groups = PricingService.getGroups()
    const categories = PricingService.getCategories()
    const [ currentMenu, setCurrentMenu ] = useState(menu)
    const currentGroup = PricingService.getGroupByIdentifier(currentMenu)
    const menuOptions: { id: Group['identifier'], title: Group['title']}[] = groups.map(group => { 
        return { id: group.identifier, title: group.title };
    });
    const isSmallScreen = useScreenSize('sm');
    const menuCalloutText = menuCallouts[currentMenu]

    function selectMenu (id: Group['identifier']) {
        setCurrentMenu(id)
    }

    return (
        <Stack direction={{ sm: 'column', md: 'row' }} spacing={6}>
            <Stack direction='column' spacing={4} sx={{ marginBottom: '32px', marginLeft: '20px', marginRight: '20px' }}>
                <Typography variant='h2' color='secondary.main' fontWeight='700'>{title}</Typography>

                {
                    isSmallScreen 
                        ? <MenuDropdown
                            currentMenuId={currentGroup.identifier}
                            options={menuOptions}
                            selectMenu={selectMenu}
                        />
                        : <MenuToggle 
                            currentMenuId={currentGroup.identifier}
                            options={menuOptions} 
                            selectMenu={selectMenu} 
                        />
                }
            </Stack>

            <Box sx={{ paddingBottom: theme.spacing(isSmallScreen ? 4 : 5) }}>
                {
                    !isSmallScreen ?
                    <Box sx={{ margin: '0 20px 40px' }}>
                        <Typography variant='h2' color='secondary.main' fontWeight='700'>{currentGroup.title}</Typography>
                    </Box> : null
                }

                {
                    !!menuCalloutText ? <Box sx={{ marginBottom: '20px' }}><Callout>
                        <Typography
                            variant='body1'
                            fontWeight={700}
                            color='primary.main'>
                            {menuCalloutText}
                        </Typography>
                    </Callout></Box> : null
                }

                {
                    categories.map((category, i) => (
                        <Box
                            key={i}
                            sx={{
                                display: category.groups.includes(currentGroup.identifier) ? 'block' : 'none',
                                backgroundColor: 'primary.main'                            
                            }}>
                                <Grid 
                                    container 
                                    direction='row'
                                    columns={1} 
                                    flexWrap='wrap'>
                                    <Box sx={{ margin: '0 20px 20px' }}>
                                        <Typography variant='h3' color='secondary.main' fontWeight='700' fontSize={'1.5em'}>{category.title}</Typography>
                                    </Box>
                                    {
                                        PricingService.getOfferingsByCategory(category.identifier).map((offering, i) => (
                                            <Grid
                                                key={i}
                                                item  
                                                flexBasis='100%'
                                                sx={{ marginBottom: i === PricingService.getOfferingsByCategory(category.identifier).length - 1 ? '20px' : 0 }}>
                                                <MenuItem item={offering} />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                        </Box>
                    ))
                }
                
            </Box>
        </Stack>
    )
}