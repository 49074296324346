import HeroSection from '../../sections/hero/HeroSection';
import WhoWhatWhy from './WhoWhatWhy';
import GeneralFAQ from './GeneralFAQ';
import Scholarships from './Scholarships';

import { useScreenSize } from '../../theme/breakpoints';

export default function Home () {
    const isSmallScreen = useScreenSize('sm')

    return (
        <>
            <HeroSection
                height={isSmallScreen ? 'calc(95vh - 95px)' : 'calc(100vh - 95px)'} // header/navigation height (95px)
                cardWidth='35vw'
                heroImagePath='hero.jpg'
                imageCover
                titleText='Monthly memberships are available!'
                descriptionText='Check out our pricing options.'
                buttonText='Buy Now'
                buttonHref='/pricing'
                backgroundPosition='0%'
            />

            <WhoWhatWhy />

            <GeneralFAQ />

            <Scholarships />
        </>
    )
}