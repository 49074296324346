import { useState, useEffect } from 'react'

import './mindbodyenrollmentwidget.css'
import loadMindBody from './loadMindBody'

export interface MindBodyEnrollmentWidgetData {
    dataWidgetId: string
}

interface Props {
    dataWidgetId: string
}

export default function MindBodyScheduleWidget (props: Props) {
    const [ isMindBody, setMindBody ] = useState(false);
    const { dataWidgetId } = props;

    useEffect(() => {
        loadMindBody(() => setMindBody(true));
    })

    return isMindBody ? (
        // @ts-ignore
        <healcode-widget
            data-type="enrollments" 
            data-widget-partner="object" 
            data-widget-id={dataWidgetId}
            data-widget-version="0" 
        />
    ) : null
}